import {States} from "../../../../../common/constants/states";

class AdminSchoolsZonesIndexController {

    constructor($state, SchoolsModel, ZonesModel, $stateParams, UserService, FlashService) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.SchoolsModel = SchoolsModel;
        this.ZonesModel = ZonesModel;
        this.FlashService = FlashService;
        this.states = States;
        this.school = UserService.getCurrentUser().school;
        this.loading = true;
        this.busy = false;
        this.zones = [];
        this.getZones();
    }

    getZones() {
        this.ZonesModel.getZones().then(response => {
            this.zones = response.zones.map(zone => {
                return zone;
            });
            this.loading = false;
        });
    }

    updateZone(zone) {
        this.busy = true;
        this.ZonesModel.editFallbackLocation(zone).then(response => {
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            })
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        })
    }

}
AdminSchoolsZonesIndexController.$inject = ['$state', 'SchoolsModel', 'ZonesModel', '$stateParams', 'UserService', 'FlashService'];
export default AdminSchoolsZonesIndexController;
