<template>
  <section id="admin-view-course">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-center">
          <h1 class="page-heading">View Course</h1>
          <h3 v-if="$store.state.viewingCourse.date">Class Date {{ $store.state.viewingCourse.date }} From {{ $store.state.viewingCourse.start }} to {{ $store.state.viewingCourse.end }}</h3>
        </div>
      </div>
    </div>
    
    <dcLoading v-if="loading"></dcLoading>

    <div class="container" v-else-if="!loading">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <dcCoursePreview
            :course="coursePreview"
            :type="'view-course'"
            :buttonDisabled="studentsAttached || courseStarted"
            @emit-event="emitEvent($event.eventName, $event.dataObject)"
            @delete-course="emitEvent('delete-course')"
          ></dcCoursePreview>
        </div>
        <div class="col-sm-12 col-md-8">
          <div class="well p0">
           
            <div class="well-header flex flex-row">
              <h2 class="well-heading mb0">Course Roster</h2>
              <div class="flex flex-row btn-container">
                <button @click="emitEvent('print')" class="btn btn-gray mr-10">
                  <i class="fa fa-circle-o-notch fa-spin fa-fw" v-if="printing"></i>
                  Print
                </button>
                <button data-cy="viewAttendance" class="btn btn-dc mr-10" :disabled="!studentsAttached" @click="emitEvent('link-click', { destination: 'drivecoach.admin.courses.attendance', params: {courseId: course.id }})">Attendance</button>
                <button data-cy="addStudent" v-if="course.policies.policymeta.allow_add_student_to_course === 'Y'" @click="openAddStudentModal" class="btn btn-blue">Add Student</button>
              </div>
            </div>
            <div class="well-content grid-container">
              <div class="grid-row header">
                <div class="grid-item table-header">Select</div>
                <div class="grid-item table-header">Contract Recieved</div>
                <div class="grid-item table-header">Name</div>
                <div class="grid-item table-header">Actions</div>
              </div>
                
              <div class="grid-row" v-for="student in options.studentsInCourse" :key="student.id">
                <div class="grid-item" style="justify-content:center;"><input style="margin-top: 0;" type="checkbox" v-model="selectedStudents" :value="student" /></div>
                <div class="grid-item">
                  <select v-model="student.pivot.received_contract" class="form-control">
                    <option v-for="option in contractOptions" :value="option.value" :key="option.name">
                      {{ option.name }}
                    </option>
                  </select>
                </div>
                <div class="grid-item">{{ student.fullname }}</div>
                <div class="grid-item btn-container">
                  <button type="button" data-cy="drivecoach.admin.course.roster.save" @click="emitEvent('save-roster', {student_id: student.id, course_id: course.id, received_contract: student.pivot.received_contract })" class="btn btn-green btn-small">Save</button>
                  <button type="button" data-cy="drivecoach.admin.students.view" @click="emitEvent('link-click', {destination: 'drivecoach.admin.students.view', params: {userId: student.id}})" class="btn btn-blue btn-small">View</button>
                  <button type="button" data-cy="remove-student" @click="emitEvent('remove-student', {id: student.id, fullname: student.fullname})" class="btn btn-red btn-small">Remove</button>
                </div>
              </div>
              <div class="grid-row" v-if="studentsAttached">
                <div class="grid-item" style="justify-content:center;"><input style="margin-top: 0;" type="checkbox" v-model="allStudentsSelected"/></div>
                <div class="grid-item" style="grid-column: 2 / 5;"><label for="" style="margin-bottom: 0; padding: 5px;">Select to Email All Students</label></div>
              </div>
            </div>
            <div class="well-header" style="margin-top:2em;">
              <h2 class="well-heading mb-1">Email Students</h2>
              <p>Use this to send emails to students in this course. <strong>Select students to email above.</strong></p>
            </div>
            <div class="well-content">
              <div style="padding: 1em;">
                <dcSendEmail
                  :courseId="course.id"
                  :selectedUsers="selectedStudents"
                  :placeholder="'Type your message here! Send Zoom link, reminder to bring permits, etc.'"
                  :emailSent="emailSent"
                  @emit-event="emitEvent($event.eventName, $event.dataObject)"
                ></dcSendEmail>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    
    <dcModal :modalShown="modalShown" :modalClasses="['col-sm-9', 'col-xs-12']" :modalCenter="true">
      <template #header>
        <div class="flex flex-row">
          <h2 class="mb0">Add Student To Course</h2>
          <button @click="hideModal" type="button" class="btn btn-red btn-small">Cancel</button>
        </div>
      </template>
      <template #body>
        <section>
          <div style="margin-bottom: 2em;">
            <p><strong>Find Student</strong></p>
            <v-select v-model="selectedStudent" 
              :options="options.searchableStudents"
              label="name"
              placeholder="Type to search for student"
              :loading="searchingStudents"
              @search="emitEvent('student-search', $event)"></v-select>
          </div>
          <div>
            <p><strong>Add to Days</strong></p>
            <label class="text-center" style="display: block; margin-bottom: 1em;">
              <button @click.prevent="toggleAllDays" class="btn btn-blue"> Toggle All Days</button>
            </label>
            <label v-for="classDate in previewClasses" :key="classDate.id" class="text-center" style="display: block; margin-bottom: 1em;">
              <input type="checkbox" :style="checkboxStyles" :value="classDate" v-model="selectedClassDates" />{{ classDate.date }} {{ classDate.startTime }} - {{ classDate.endTime }}
            </label>
          </div>
        </section>
      </template>
      <template #footer>
      <button @click="emitEvent('add-student', {'student': selectedStudent, 'classes': selectedClassDates})" type="button" class="btn btn-dc btn-block">
          ADD STUDENT TO COURSE
        </button>
      </template>
    </dcModal>

  </section>
</template>

<script>
import moment from 'moment'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import dcModal from '../../../common/directives/vue-directives/modal/Modal.vue'
import dcLoading from '../../../common/directives/vue-directives/loading/Loading.vue'
import dcSendEmail from '../../../common/directives/vue-directives/send-email/SendEmail.vue'

import dcCoursePreview from '../components/CoursePreview.vue'

export default {
  name: 'dcViewCourse',
  components: { dcCoursePreview, dcModal, vSelect, dcLoading, dcSendEmail },
  props: { 
    course: { type: Object, default: () => {} },
    searchableStudents: { type: Array, default: () => [] },
    studentsInCourse: { type: Array, default: () => [] },
    searchingStudents: { type: Boolean, default: false },
    submitSuccess: { type: Boolean, default: false },
    emailSent: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    printing: { type: Boolean, default: false }
  },
  data() {
    return {
      checkboxStyles: {
        marginRight: '10px',
        marginBottom: '5px',
        verticalAlign: 'bottom',
        position: 'relative',
        top: '2px'
      },
      contractOptions: [{"name":"Yes","value":1}, {"name":"No","value":0}],
      modalShown: false,
      selectedStudent: null,
      selectedClassDates: [],
      selectedStudents: [],
    }
  },
  methods: {
    emitEvent: function(eventName, dataObject) {
      this.$emit(eventName, dataObject)
    },
    openAddStudentModal: function(data) {
      if(!this.modalShown) {
        this.modalShown = true;
      }
    },
    hideModal() {
      if(this.modalShown) {
        this.modalShown = false
      }
    },
    toggleAllDays() {
      this.previewClasses.forEach(classDate => {
        if (this.selectedClassDates.indexOf(classDate) === -1) {
          this.selectedClassDates.push(classDate);
        } else {
          this.selectedClassDates.splice(this.selectedClassDates.indexOf(classDate), 1);
        }
      });
    }
  },
  computed: {
    options: function() {
      return {
        searchableStudents: this.searchableStudents,
        studentsInCourse: this.studentsInCourse.sort((a, b) => a.lastname.localeCompare(b.lastname))
      }
    },
    studentsAttached: function() {
      return this.course.students.length > 0
    },
    allStudentsSelected: {
      get() {
        if (this.studentsInCourse && this.studentsInCourse.length > 0) { // A users array exists with at least one item
          let allSelected = true;
          for (const student of this.studentsInCourse) {
            if (!this.selectedStudents.includes(student)) {
              allSelected = false; // If even one is not included in array
            }
            // Break out of loop if mismatch already found
            if(!allSelected) break;
          }
          return allSelected;
        }
        return false;
      },
      set(value) {
        const selected = [];
        this.studentsInCourse.forEach((student) => {
          if(value === true) {
            selected.push(student);
          }
        });
        this.selectedStudents = selected;
      }
    },
    // classDates: function() {
    //   return [].concat(this.course.classes).map(classDate => {
    //     classDate.start = new Date(classDate.start).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })
    //     classDate.end = new Date(classDate.end).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })
    //     return classDate;
    //   });
    // },
    courseStarted: function() {
      let start;
      if (this.course.classes.length > 0) {
        start = moment(this.course.classes[0].start);
      } else {
        start = moment(`${this.course.date} ${this.course.time}`);
      }
      let now = moment();

      return now.isAfter(start);
    },
    coursePreview: function() {
      let course = this.course;
      return {
        id: course.id,
        name: course.name,
        type: course.type.name,
        number_of_seats: course.number_of_seats,
        location: course.location.name, //location and classroom are both index based so this just auto selects the first option
        classroom: course.classroom.name,
        description: course.description,
        classes: this.previewClasses
      }
    },
    previewClasses: function() {
      return this.course.classes.map((classTime) => {
        return {
          id: classTime.id,
          date: moment(classTime.start).format('M / D / YYYY'),
          startTime: moment(classTime.start).format('hh:mm A'),
          endTime: moment(classTime.end).format('hh:mm A'),
          instructor: {
            id: classTime.instructor.id,
            fullname: classTime.instructor.fullname,
          }
        }
      });
    }
  },
  watch: {
    submitSuccess() {
      if(this.submitSuccess === true) {
        this.modalShown = false;
        this.selectedStudent = null;
        //this hides the modal when a student is successfually added
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../../assets/css/partials/variables.scss';
  @import '../../../../assets/css/partials/grid.scss';
  
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &.btn-container {
      justify-content: flex-end;
    }
  }
  
  .well {
    border-radius: 8px;
    box-shadow: 0px 3px 6px #0000001D;
    border: 1px solid #F4F4F4;
    padding: 15px;
    width: 100%;
    margin-bottom: 20px;

    h2 {
      font-size: 140%;
      font-weight: 300;
      text-align: left;
    }
    .well-header {
      padding: 15px;
    }
    &.p0 {
      padding: 0;
    }
    .well-item {
      &.product {
        padding: 10px 15px;
        border-top: 2px solid #E1E7EB;
      }
    }
  }

</style>