import { PolicyMeta } from './PolicyMeta'
const SystemPolicies = ['drives','cancellations','notifications','events','courses','payratios','billing','calendar','storefront','branding', 'onlinedriversed'];

class Policy {

    
    constructor(id, name, meta = []) {
        this.$id = id;
        if (SystemPolicies.includes(name) === false){
            throw new Error(`Invalid Policy name: ${name}`);
        }
        this.$name = name;

        this.$meta = meta;
    }

    id() {
        return this.$id;
    }

    name() {
        return this.$name;
    }

    meta() {
        return this.$meta;
    }

    setMetaData(data = []) {
        this.$meta = [].concat([...data]);
    }

    getMetaValueByKey(key = ''){
        let policyMeta = this.$meta.filter(meta => {
            return meta.key() === key;
        });

        if (policyMeta.length === 0) {
            throw Error(`Meta key with name ${key} was not found.`);
        }

        return policyMeta[0].value();
    }

    /**
     * Set meta value by key 
     * @param {String} key 
     * @param {*} value 
     */
    setMetaValueByKey(key, value){
        let policyMeta = this.$meta.filter(meta => {
            return meta.key() === key;
        });

        if (policyMeta.length === 0) {
            const $meta = new PolicyMeta(
                0, this.id(), key, value
            );
           this.setMetaData([...this.meta(), $meta]);
        } else {
            policyMeta[0].setValue(value);
            const $meta = policyMeta[0];
            const $metaCollection = this.meta().filter(m => {
                return m.key() !== key;
            });
            this.setMetaData([...$metaCollection, $meta]);
        }
    }

    metaExistsByKey(key = '') {
        let policyMeta = this.$meta.filter(meta => {
            return meta.key() === key;
        });

        if (policyMeta.length === 0) {
            return false;
        }

        return true;
    }

}

export { Policy };
