function AuthInterceptor($q, $rootScope, FlashService, AUTH_EVENTS, $timeout, $injector, VueStoreFactory) {
    const service = this;

    service.responseError = responseError;
    service.request = request;

    /////////////////////////

    function request(config) {
        let deferred = $q.defer();
        let AuthService = $injector.get('AuthService');
        config.withCredentials = config.url.includes("drivescout");
        config.headers['DS_CLIENT_VERSION'] = '1.19.10';
        const token = AuthService.getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            // VueStoreFactory.dispatch('SET_TOKEN', token)
        }
        deferred.resolve(config);
        return deferred.promise;
    }

    function responseError(response) {
        $rootScope.$broadcast( {
            401 : AUTH_EVENTS.notAuthenticated,
            403 : AUTH_EVENTS.notAuthorized,
            409 : AUTH_EVENTS.conflict,
            419 : AUTH_EVENTS.sessionTimeout,
            440 : AUTH_EVENTS.sessionTimeout
        }[response.status], response );

        if (response.status === 401) {
            let message = parseMessage(response, 'Username or password incorrect.');
            FlashService.setMessage({'type': 'error', 'message' : message});
            VueStoreFactory.dispatch('LOGOUT')
            // $injector.get('$state').go('drivecoach.logout');
        }

        if (response.status === 409) {
            let message = parseMessage(response, 'What you are trying to do conflicts with the current state of the resource.')
            FlashService.setMessage( {
                'type' : 'danger',
                'message' : message
            } );
        }

        if (response.status === 422) {
            let message = parseMessage(response, 'What you are trying to do conflicts with the current state of the resource.')
            FlashService.setMessage( {
                'type' : 'danger',
                'message' : message
            } );
        }

        if (response.status === 400) {
            let message = 'The request could not be understood by the server. Please log in and try again.';
            let toLogin = true;
            if( response.hasOwnProperty('data') ){
                if (response.data.hasOwnProperty('error')) {
                    message = 'Your session has expired, please log in.';
                    toLogin = true;
                    // logout();
                }
                if( response.data.hasOwnProperty('detail') ){
                    toLogin = false;
                    message = response.data.detail;
                }
            }
            FlashService.setMessage( {
                'type' : 'warning',
                'message' : message
            } );
            if(toLogin === true){
                // logout();
                $timeout(function(){
                    $injector.get('$state').go('drivecoach.login');
                }, 1000);
            }
        }

        if ( response.status === 403 ) {
            let message = 'You do not have permission to do that.';
            //VueStoreFactory.dispatch('LOGOUT');
            if( response.hasOwnProperty('data') ){
                if( response.data.hasOwnProperty('detail') ){
                    message = response.data.detail;
                }
                if( response.data.hasOwnProperty('message') ){
                    message = response.data.message;
                }
            }
            FlashService.setMessage( {
                'type' : 'warning',
                'message' : message
            } );
        }

        if ( response.status === 419 || response.status === 440 ) {
            FlashService.setMessage( { 'type' : 'warning', 'message' : 'Your session has timed out, you have been logged out.' } );
            service.logout().then(() => {
                $timeout(() => {
                    $injector.get('$state').go('drivecoach.login');
                }, 1000);
            });
        }

        if ( response.status === 500 || response.status === 404 ) {
            let message = 'Internal server error';
            message = parseMessage(response, message);
            FlashService.setMessage( { 'type' : 'danger', 'message' : message } )
        }

        return $q.reject( response );

    }

    function parseMessage(response, defaultMessage){
        let newMessage = null;
            if ( response.message ) {
            newMessage = response.message;
            }
            if ( response.data.message ) {
            newMessage = response.data.message;
            }
            if ( response.detail ) {
            newMessage = response.detail;
            }
            if ( response.data.detail ) {
            newMessage = response.data.detail;
        }
        if( response.hasOwnProperty('data') ){
            if( response.data.hasOwnProperty('detail') ){
                newMessage = response.data.detail;
            }
            }
        if ( response.data.hasOwnProperty('error') && response.data.error.message ) {
            newMessage = response.data.error.message;
            }
            if( response.data.meta ){
                if( response.data.meta.hasOwnProperty('message') ){
                newMessage = response.data.meta.message;
                }
            }
            if (response.data.errors) {
                for(let prop in response.data.errors){
                    newMessage = response.data.errors[prop][0];
                }
            }
        if(newMessage === null){
            return defaultMessage;
        }
        return newMessage;
    }
}
AuthInterceptor.$inject = ['$q', '$rootScope', 'FlashService', 'AUTH_EVENTS', '$timeout', '$injector', 'VueStoreFactory'];
export default AuthInterceptor;