import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import {Student} from '../../../../classes/Student';
import {Order} from '../../../../classes/Order';
import { isJson } from '../../../../common/helpers/json';
import { saveToDisk } from '../../../../common/helpers/saveToDisk';
import { dateStringToDate, toYMD } from '../../../../common/helpers/time';

class AdminStudentViewController {

    constructor($state, $stateParams, UsersModel, FlashService, $window, AdminTasksService, CreateOrderService, balance, $q, APPURL, PoliciesModel, OnlineDriversEd, $sce) {
        this.$q = $q;
        this.$sce = $sce;
        this.APPURL = APPURL;
        AdminTasksService.clearTasks();
        this.CreateOrderService = CreateOrderService;
        this.AdminTasksService = AdminTasksService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.currentPage = 1;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.student = {};
        this.hasNextSession = false;
        this.initialLoad = true;
        this.busy = false;
        this.pickuplocations = [];
        this.using_drive_deadline = false;

        this.studentNotes = {
            currentPage: 0,
            page_by: 5,
            data: [],
            total: 0,
        }

        this.updateDriveDeadline = this.updateDriveDeadline.bind(this);

        PoliciesModel.getByID('drives').then(response => {
            this.using_drive_deadline = response.policy.policymeta.find(meta => meta.meta_key === 'enable_drive_deadline').meta_value === 'Y';
        }).catch(Error => {
            this.using_drive_deadline = false;
        });

        this.OnlineDriversEd = OnlineDriversEd;
        this.onlineDriversEdConfig = {
            'available' : false,
            'enabled' : false
        }

        this.messengerUsers = [];

        UsersModel.getStudentUsageDetails($stateParams.userId).then(response => {
            this.usageDetails = response.data;
        });

        UsersModel.getStudentCourseDetails($stateParams.userId).then( response => {
            this.courses = response.data;
        });

        this.requirements = [];
        UsersModel.requirements($stateParams.userId).then( response => {
            this.requirements = response.requirements;
        });

        let getStudent = UsersModel.getStudentByID($stateParams.userId);
        let getPickupLocations = UsersModel.getPickupLocations($stateParams.userId);
        let getOnlineDriversEdDetails = OnlineDriversEd.getFeatureDetails();
        let getStudentNotes = UsersModel.getStudentNotes(this.$stateParams.userId);

        $q.all([getStudent, getPickupLocations, getOnlineDriversEdDetails, getStudentNotes]).then(responses => {
            if(responses[0].usermeta.permit) {
                responses[0].usermeta.permit.issuedate = moment(responses[0].usermeta.permit.issuedate).toDate();
                responses[0].usermeta.permit.expirationdate = moment(responses[0].usermeta.permit.expirationdate).toDate();
            }

            if (responses[0].usermeta.home_address) {
                this.home_address = responses[0].usermeta.home_address
            }

            responses[0].school.address = isJson(responses[0].school.address) ? JSON.parse(responses[0].school.address) : responses[0].school.address;
            responses[0].amount_owed = balance;
            responses[0].zoneparts = uniqBy(responses[0].zoneparts, 'zone_id');

            this.pickuplocations = responses[1].pickuplocations;

            if (responses[0].usermeta.hasOwnProperty('drive_deadline')) {
                responses[0].usermeta.drive_deadline = dateStringToDate(responses[0].usermeta.drive_deadline);
            } else {
                responses[0].usermeta.drive_deadline = null;
            }

            this.student = responses[0];

            this.onlineDriversEdConfig.available = responses[2].available;
            this.onlineDriversEdConfig.enabled = responses[2].enabled;

            this.messengerUsers.push({
                id: this.student.id,
                name: this.student.fullname,
            });

            // Set an empty new order with this student attached to it if the admin visits the "create order" page
            const $student = new Student(this.student);
            const order = new Order();
            order.setStudent($student);
            this.CreateOrderService.cashier().setOrder(order);

            this.student.pickuplocations = this.pickuplocations;

            // notes
            this.studentNotes.data = responses[3].notes.data;
            this.studentNotes.total = responses[3].notes.total;
            this.studentNotes.currentPage = responses[3].notes.current_page;

            this.getNextSession();
        });

    }

    toggleOnlineDriversEdRequired()
    {
        this.OnlineDriversEd.updateOnlineDriversEdFields({
            id: this.student.id,
            ode_required : this.student.ode_required,
            ode_completed : this.student.ode_completed
        }).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message': response.message
            })
        }).catch(Error => {
            this.FlashService.setMessage({
                'type' : 'error',
                'message': Error.message
            })
        })
    }

    printProfile()
    {
        this.UsersModel.printStudentAccount(this.student.id).then(response => {
            this.busy = false;
            let filename = response.file.split("/")[response.file.split("/").length-1]
            saveToDisk(response.file, filename);
        }).catch(Error => {
            this.busy = false;
        })
    }
    archiveUser() {
        this.UsersModel.archiveStudent(this.student.id).then(response => {
            const confirm = this.$window.confirm("Are you sure you want to archive this student?");
            if (!confirm) {
                return;
            }
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Archived';
        });
    }

    activateUser() {
        this.UsersModel.activateStudent(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Active';
        });
    }

    deactivateUser() {
        this.UsersModel.deactivateStudent(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Inactive';
        });
    }
    updateDriveDeadline(newDate)
    {
        this.student.usermeta.drive_deadline = toYMD(newDate);
        this.UsersModel.updateDriveDeadline(this.student.id, {drive_deadline: toYMD(newDate)}).then(response => {
            this.student.deadline = response.student.deadline;
            this.student.status = response.student.status;
            this.FlashService.setMessage({
                'message' : response.message,
                'type' : 'success'
            });
        }).catch(Error => {
            console.error(Error);
        })
    }
    getNextSession() {
        this.UsersModel.getNextStudentEntity({'id': this.student.id}).then(response => {
            if( isEmpty(response.entity) ){
                this.nextSession = {};
                this.hasNextSession = false;
            } else {
                this.nextSession = {
                    'nextDrive': response.entity,
                    'nextDriveDate': moment(response.entity.date + ' ' + response.entity.time),
                    'untilDrive': moment(response.entity.date + ' ' + response.entity.time).format('h:mm a')
                }
                this.hasNextSession = true;
            }
            this.initialLoad = false;
        } )

    }

    sendWelcomeEmail() {
        const confirm = this.$window.confirm('Are you sure you want to send the welcome email? This will also reset this users password.');
        if (confirm === false) {
            return false;
        }
        this.busy = true;
        this.UsersModel.sendWelcomeEmail(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.busy = false;
        });
    }

    /// notes

    formatNoteContent(note){
        return this.$sce.trustAsHtml(note);
    }

    deleteNote(index, note) {
        if (note.id){
            this.submitting = true;
            this.UsersModel.removeStudentNote(this.student.id, note.id).then(response => {
                this.FlashService.setMessage({
                    'type' : 'success',
                    'message' : response.message
                });
                this.studentNotes.data = this.studentNotes.data.filter(n => {
                    return note.id !== n.id;
                });
                this.submitting = false;
            }).catch(Error => {
                this.submitting = false;
            });
        } else {
            this.studentNotes.data.splice(index, 1)
        }
    }

    getRole(role)
    {
        if (role === 1) {
            return 'Students';
        }

        if (role === 3) {
            return 'Instructors';
        }

        if (role === 4) {
            return 'Owners';
        }

        if (role === 5) {
            return 'Administrators';
        }

        if (role === 9) {
            return 'Staff';
        }

        return 'Owner';
    }

    studentNotesPageChanged()
    {
        this.submitting = true;
        this.UsersModel.getStudentNotes(this.student.id, {page: this.studentNotes.currentPage}).then(
        (response) => {
            this.studentNotes.data = response.notes.data;
            this.studentNotes.total = response.notes.total;
            this.studentNotes.currentPage = response.notes.current_page;
            this.submitting = false;
        }).catch(Error => {
            this.submitting = false;
        });
    }
}

AdminStudentViewController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', '$window', 'AdminTasksService', 'CreateOrderService', 'balance', '$q', 'APPURL', 'PoliciesModel', 'OnlineDriversEd', '$sce'];
export default AdminStudentViewController;
