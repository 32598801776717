import {States} from "../../../../../common/constants/states";

class AdminSchoolZoneCreateController {

    constructor($state, $stateParams, LocationsModel, SchoolsModel, ZonesModel, FlashService) {
        this.$state = $state;
        this.states = States;
        this.$stateParams = $stateParams;
        this.LocationsModel = LocationsModel;
        this.SchoolsModel = SchoolsModel;
        this.ZonesModel = ZonesModel;
        this.FlashService = FlashService;
        this.custom_center = false;
        this.zone_center = {};
        this.zone = {'sectors': 0};
        SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
            this.school = response;
            this.locations = response.locations;
        })
    }

    createZone(isValid) {
        this.submitted = true;
        if (this.zone_center.street && this.zone_center.city && this.zone_center.state && this.zone_center.state) {
            this.zone.location = this.zone_center.street + ',' + this.zone_center.city + ',' + this.zone_center.state + ',' + this.zone_center.zip;
        }
        if (isValid) {
            this.ZonesModel.createZone(this.zone).then(response=> {
                this.FlashService.setMessage({'type': 'success', 'message': response.message});
                this.zone = {'sectors': 0};
                this.$state.go('drivecoach.admin.schools.zones.index', {'schoolId': this.$stateParams.schoolId}, {'reload': true});
                this.submitted = false;
                this.zone_center = {};
                this.custom_center = false;
            });
        } else {
            this.FlashService.setMessage({'type': 'error', 'message': 'Please fill the form.'});
        }
    }
}
AdminSchoolZoneCreateController.$inject = ['$state', '$stateParams', 'LocationsModel', 'SchoolsModel', 'ZonesModel', 'FlashService'];
export default AdminSchoolZoneCreateController;