class AdminSchoolsLocationsIndexController {

    constructor(SchoolsModel, $stateParams, LocationsModel, FlashService){
        this.SchoolsModel = SchoolsModel;
        this.LocationsModel = LocationsModel;
        this.FlashService = FlashService;
        this.loading = true;
        SchoolsModel.getSchoolByID($stateParams.schoolId).then(response => {
            this.locations = response.locations;
            this.loading = false;
        });
    }

    disableLocation(location) {
        this.LocationsModel.disableLocation(location).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    }

    activateLocation(location) {
        this.LocationsModel.activateLocation(location).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
        });
    }

}
AdminSchoolsLocationsIndexController.$inject = ['SchoolsModel', '$stateParams', 'LocationsModel', 'FlashService'];
export default AdminSchoolsLocationsIndexController